<template>
  <nav :class="{'mt-5':!logged, ' mx-auto':logged&&vertical}" class="adminbar row">
    <div :class="{'col-md-3 ':logged&&!vertical}" class="col-12">
      <div :class="{'isExit':logged}" class="logo">
        <img
          v-lazy-load data-src="/img/tejhabos-logo-dark.png"
          class="d-inline-block align-top logo"
          alt="Dear Society"
        >
      </div>
    </div>
    <div v-if="logged" :class="{'col-md-9 text-right':!vertical,'text-center mt-3':vertical}" class="col-12 ">
      <p :class="{'py-4':!vertical}" class="my-1">
        Belépve, mint <strong class="text-primary">{{ user().name }}</strong>
      </p>
      <ul :class="{'mt-5':vertical}">
        <li v-for="(item, index) in menus" :key="index" :class="{'d-inline':!vertical}">
          <nuxt-link :to="item.url">
            {{ item.name }}
          </nuxt-link>
        </li>
        <li :class="{'mt-5':vertical, 'd-inline':!vertical}">
          <button @click="logout()" type="button" class="btn btn-text text-primary">
            Kilépés
          </button>
        </li>
      </ul>
    </div>
    <div v-else class="col-12 text-center">
      <p class="my-1 ">
        Szia, a folytatáshoz kérünk lépj be!
      </p>
    </div>
  </nav>
</template>

<script>
const Cookie = process.client ? require('js-cookie') : undefined

export default {
  props: {
    menus: {
      type: Array,
      default: () => {
        return []
      }
    },
    logged: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDashboard () {
      return this.$route.path === '/admin/kozpont'
    },
    isLogin () {
      return this.$route.path === '/admin/login'
    }
  },
  methods: {
    user () {
      return this.$store.getters['auth/user']
    },
    logout () {
      Cookie.remove('__session')
      this.$store.commit('auth/setToken', null)
      this.$store.commit('auth/setUser', null)
      this.$router.push('/admin/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.adminbar {
  margin-top: 15px;
  .logo{
    img{
      height:100px;
    }
    &.isExit{
    img{
        height:75px;
      }
    }
  }
  ul {
    list-style: none;
    padding-left:0;
    li {

      padding: 0 10px;
      &:last-child {
        border: none;
      }
    }
  }
  &.plus-padding {
    padding-top: 20vh;
  }
}
</style>
