import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7566d50c = () => interopDefault(import('..\\pages\\kereses\\index.vue' /* webpackChunkName: "pages/kereses/index" */))
const _0e162225 = () => interopDefault(import('..\\pages\\rolam\\index.vue' /* webpackChunkName: "pages/rolam/index" */))
const _206901b6 = () => interopDefault(import('..\\pages\\admin\\cikkek\\index.vue' /* webpackChunkName: "pages/admin/cikkek/index" */))
const _41f05bfe = () => interopDefault(import('..\\pages\\admin\\kategoriak\\index.vue' /* webpackChunkName: "pages/admin/kategoriak/index" */))
const _5b8e5db0 = () => interopDefault(import('..\\pages\\admin\\kozpont\\index.vue' /* webpackChunkName: "pages/admin/kozpont/index" */))
const _6e8b4bda = () => interopDefault(import('..\\pages\\admin\\login\\index.vue' /* webpackChunkName: "pages/admin/login/index" */))
const _7aac74fb = () => interopDefault(import('..\\pages\\admin\\rovatok\\index.vue' /* webpackChunkName: "pages/admin/rovatok/index" */))
const _8374ccf2 = () => interopDefault(import('..\\pages\\admin\\cikkek\\cikk\\_id.vue' /* webpackChunkName: "pages/admin/cikkek/cikk/_id" */))
const _7e88d456 = () => interopDefault(import('..\\pages\\rovat\\_slug\\index.vue' /* webpackChunkName: "pages/rovat/_slug/index" */))
const _763af40f = () => interopDefault(import('..\\pages\\cikk\\_id\\_title.vue' /* webpackChunkName: "pages/cikk/_id/_title" */))
const _96732a58 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kereses",
    component: _7566d50c,
    name: "kereses"
  }, {
    path: "/rolam",
    component: _0e162225,
    name: "rolam"
  }, {
    path: "/admin/cikkek",
    component: _206901b6,
    name: "admin-cikkek"
  }, {
    path: "/admin/kategoriak",
    component: _41f05bfe,
    name: "admin-kategoriak"
  }, {
    path: "/admin/kozpont",
    component: _5b8e5db0,
    name: "admin-kozpont"
  }, {
    path: "/admin/login",
    component: _6e8b4bda,
    name: "admin-login"
  }, {
    path: "/admin/rovatok",
    component: _7aac74fb,
    name: "admin-rovatok"
  }, {
    path: "/admin/cikkek/cikk/:id?",
    component: _8374ccf2,
    name: "admin-cikkek-cikk-id"
  }, {
    path: "/rovat/:slug",
    component: _7e88d456,
    name: "rovat-slug"
  }, {
    path: "/cikk/:id?/:title?",
    component: _763af40f,
    name: "cikk-id-title"
  }, {
    path: "/",
    component: _96732a58,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
