<template>
  <div>
    <div id="mainContent">
      <div class="sidebar-overlay" />

      <!-- Site Header
        ================================================== -->
      <topbar />

      <!-- Nav Bar
        ================================================== -->
      <navbar />
      <!-- class="section-padding" -->
      <transition name="fade" mode="out-in">
        <nuxt />
      </transition>
      <mfooter />
    </div>
  </div>
</template>
<script>
import topbar from '~/components/front/topbar.vue'
import navbar from '~/components/front/navbar.vue'
const mfooter = () =>
  import('~/components/front/footer.vue')
export default {
  components: {
    topbar,
    navbar,
    mfooter
  }
}
</script>
<style lang="scss">
  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    transition: opacity 0.5s;
  }

  .fade-leave-active {
    transition: opacity 1s;
    opacity: 0;
  }
</style>
