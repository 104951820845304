import Vue from 'vue';

import {
  CollapsePlugin,
  CarouselPlugin,
  DropdownPlugin,
  FormCheckboxPlugin
} from 'bootstrap-vue';

Vue.use(CollapsePlugin);
Vue.use(CarouselPlugin);
Vue.use(DropdownPlugin);
Vue.use(FormCheckboxPlugin);
