<template>
  <div @click="showSidebar = false">
    <aside id="topSidebar" ref="topSidebar">
      <div :class="{ 'show-sidebar': showSidebar }" class="sidebar">
        <button class="icon">
          <span />
          <span />
          <span />
        </button>

        <div class="about_widget">
          <!-- <h3 class="widget_title">
            <span>Rólam</span>
          </h3> -->

          <div class="avatar_author">
            <nuxt-link to="/rolam">
              <img v-lazy-load data-src="/img/authors/papai_anita.jpg" alt="Pápai Anita">
            </nuxt-link>
          </div>
          <div class="desc_author">
            <h4>Szia!</h4>
            <p class="mb-1">
              Nita vagyok, a kávé szerelmese. Kortyolj velem egyet, és éljük meg együtt az élet tejhabosságát.
            </p>
            <p class="sign mb-1">
              Pápai Anita
            </p>
            <div class="scoial-icon mb-20">
              <a href="https://www.facebook.com/atejhabosmindenit" target="_blank"><span><i class="fab fa-facebook-f" /></span></a>
              <!-- <a href="#0"><span><i class="fab fa-twitter" /></span></a> -->
              <!-- <a href="#0"><span><i class="fab fa-linkedin-in" /></span></a> -->
              <a href="https://www.instagram.com/atejhabosmindenit/" target="_blank"><span><i class="fab fa-instagram" /></span></a>
              <!-- <a href="#0"><span><i class="fab fa-behance" /></span></a> -->
            </div>
          </div>
        </div>

        <div class="categories_widget">
          <!-- <h3 class="widget_title">
            <span>Kategóriák</span>
          </h3> -->

          <div
            class="categories_item"
          >
            <nuxt-link
              to="/"
            >
              <span class="categories_title">Főoldal</span>
            </nuxt-link>
          </div>
          <div
            class="categories_item"
          >
            <nuxt-link
              to="/rolam"
            >
              <span class="categories_title">A tejhabos lányról</span>
            </nuxt-link>
          </div>
          <div
            v-for="(column, index) in columns"
            :key="index"
            class="categories_item"
          >
            <nuxt-link
              :to="'/rovat/' + column.slug"
            >
              <!-- <span class="category_image cover-bg" data-image-src="/img/posts/p2.jpg" /> -->
              <span class="categories_title">{{ column.name }}</span>
            </nuxt-link>
          </div>

          <!-- <div class="categories_item">
            <span class="category_image cover-bg" data-image-src="/img/posts/p3.jpg" />
            <span class="categories_title">Hollywood</span>
            <span class="categories_count">5 Posts</span>
            <a href="#" class="categories_link" />
          </div>

          <div class="categories_item">
            <span class="category_image cover-bg" data-image-src="/img/posts/p1.jpg" />
            <span class="categories_title">Tips & Tricks</span>
            <span class="categories_count">6 Posts</span>
            <a href="#" class="categories_link" />
          </div> -->
        </div>
      </div>
    </aside>
    <div class="header-controller text-center">
      <div class="container">
        <nav id="nav" class="navbar navbar-expand-lg nav-overlay d-none d-lg-flex">
          <div class="collapse navbar-collapse ">
            <ul class="navbar-nav mx-auto">
              <li class="dropdown nav-item">
                <nuxt-link to="/" class="nav-link">
                  Főoldal
                </nuxt-link>
              </li>

              <li class="dropdown nav-item">
                <nuxt-link to="/rolam" class="nav-link">
                  A tejhabos lányról
                </nuxt-link>
              </li>

              <li
                v-for="(column, index) in columns"
                :key="index"
                class="dropdown nav-item"
              >
                <nuxt-link
                  ref=""
                  :to="'/rovat/' + column.slug"
                  class="nav-link"
                >
                  {{ column.name }}
                </nuxt-link>
              </li>
            </ul>
          </div>

          <div class="search_trigger">
            <a class="nav-search search-trigger" href="#"><span @click="showSearch = true" class="jam jam-search" /></a>
          </div>
        </nav>

        <nav class="navbar navbar-expand-lg nav-overlay d-lg-none">
          <div class="search_trigger w-100 ">
            <a class="nav-search search-trigger" href="#"><span @click="showSearch = true" class="jam jam-search" /></a>

            <button @click="openSidebar($event)" class="icon">
              <span />
              <span />
              <span />
            </button>
          </div>
        </nav>
      </div>
    </div>
    <div :class="{ 'show-search': showSearch }" class="search-wrap">
      <div class="search-inner">
        <div class="search-cell">
          <form v-on:submit.prevent="search()" method="get">
            <div class="search-field-holder">
              <input v-model="searchWord" type="search" class="form-control main-search-input" placeholder="keresés">
            </div>
          </form>
        </div>
        <span id="search-close" @click="showSearch = false" class="icon-close jam jam-close open" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  key (route) {
    return route.fullPath
  },
  data () {
    return {
      searchWord: '',
      showSidebar: false,
      showSearch: false
    }
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      columns: 'columns'
      // ...
    })
  },
  watch: {
    showSidebar () {
      if (this.showSidebar) {
        document.addEventListener('click', this.outsideClickHandler)
        document.addEventListener('touchstart', this.outsideClickHandler)
      } else {
        document.removeEventListener('click', this.outsideClickHandler)
        document.removeEventListener('touchstart', this.outsideClickHandler)
      }
    }
  },
  methods: {
    outsideClickHandler (e) {
      if (this.$refs.topSidebar && !this.$refs.topSidebar.children[0].contains(e.target)) {
        this.showSidebar = false
      }
    },
    openSidebar (e) {
      this.showSidebar = true
      e.stopPropagation()
    },
    search () {
      this.showSearch = false
      this.$router.push('/kereses?search=' + this.searchWord)
      this.searchWord = ''
      // this.$forceUpdate()
    }
  }
}
</script>
<style lang="scss">
@import "~/assets/sass/front/_variables.scss";
.search_trigger {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
}
.show-search{
  display: block;
}
#mainContent {
  .navbar {
    border-top: none;
  }

  .secondary {
    .navbar {
      border-bottom: none;
    }
    .navbar.nav-overlay {
      .icon span  {
        background-color: $tejhabos-header-color;
      }
      .search_trigger .nav-search span {
        color: $tejhabos-header-color;
      }
    }
    .navbar.nav-overlay .navbar-nav > .nav-item > a {
      color: $tejhabos-header-color !important;
    }

    .fixed-header-controller-down {
      .navbar {
        border-top: none;
        border-bottom: none;
        background-color: white;
      }

      .navbar.nav-overlay {
        .icon span  {
          background-color: #1e1e1e;
        }
        .search_trigger .nav-search span {
          color: #1e1e1e;
        }
      }
      .navbar.nav-overlay .navbar-nav > .nav-item > a {
        color: black !important;
      }
    }
  }
}
</style>
