export const state = () => {
  return {
    columns: [],
    articles: []
  }
}

export const actions = {
  async nuxtServerInit (store, context) {
    try {
      const responses = await Promise.all([
        context.app.$axios.get('/columns'),
        context.app.$axios.get('/articles?limit=3&sort=-published_at')
      ])

      this.commit('setColumns', responses[0].data.data)
      this.commit('setArticles', responses[1].data.data)
    } catch (e) {
      console.log(e)
    }

    return store.dispatch('auth/init', context)
  }
}

export const mutations = {
  setToken (state, token) {
    state.token = token
  },
  setUser (state, user) {
    state.user = user
  },
  setDebug (state, debug) {
    state.debug = debug
  },

  setColumns (state, data) {
    state.columns = data
  },
  setArticles (state, data) {
    state.articles = data
  }
}
export const getters = {
  loggedIn: (state, getters) => {
    return state.user !== null
  },
  user: (state, getters) => {
    return state.user
  },
  columns: state => state.columns,
  articles: state => state.articles
}
