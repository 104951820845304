<template>
  <div class="container-fluid">
    <div class="row">
      <div :class="{'col-md-2 bg-light px-0 min-vh-100 position-fixed':isVertical&&isLoggedIn()}" class="col-12 text-center">
        <adminbar :menus="menu" :logged="isLoggedIn()" :vertical="isVertical" />
      </div>
      <div :class="{'col-md-10 offset-md-2':isVertical&&isLoggedIn()}" class="col-12">
        <nuxt />
      </div>
    </div>
  </div>
</template>
<script>
import adminbar from '@/components/adminbar.vue'
export default {
  components: {
    adminbar
  },
  head () {
    return {
      link: [
        { rel: 'stylesheet', href: 'https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css' }
      ]
    }
  },
  data () {
    return {
      isVertical: true,
      menu: [
        {
          name: 'Cikkek',
          icon: 'icon ion-ios-paper',
          url: '/admin/cikkek',
          sub: [
            { name: 'Összes', url: '/admin/cikkek' },
            { name: 'Új ', url: '/admin/cikkek/cikk' }
          ]
        },
        {
          name: 'Kategóriák',
          icon: 'icon ion-ios-paper',
          url: '/admin/kategoriak',
          sub: [
            { name: 'Összes', url: '/admin/kategoriak' }
            // { name: 'Új', url: '' }
          ]
        },
        {
          name: 'Rovatok',
          icon: 'icon ion-ios-paper',
          url: '/admin/rovatok',
          sub: [
            { name: 'Összes', url: '/admin/rovatok' }
            // { name: 'Új', url: '' }
          ]
        }
      ]
    }
  },
  methods: {
    user () {
      return this.$store.getters['auth/user']
    },
    isLoggedIn () {
      return this.$store.getters['auth/loggedIn']
    }
  }
}
</script>
<style lang="scss" scoped>
  // @import '~/assets/sass/main.scss';
</style>
